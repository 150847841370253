var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hidden)?_c('div',{staticClass:"dialog-wrapper"},[_c('div',{class:[
      'dialog-wrap',
      { radius: _vm.borderRadius },
      { willclose: _vm.willclose },
      { goup: _vm.buttonReverse } ],style:({ width: _vm.width + 'px' })},[_c('div',{class:['box-header', { hidden: !_vm.showHeaderBg }]},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),(_vm.showCloseBtn)?_c('div',{staticClass:"btn-close",on:{"click":_vm.closeDialog}},[_c('i',{staticClass:"el-icon-close"})]):_vm._e()]),_c('div',{staticClass:"box-body",style:({ height: _vm.height + 'px' })},[_vm._t("default")],2),(!_vm.hideFooter)?_c('div',{class:[
        'box-footer',
        { hidden: !_vm.showFooterBg },
        { reverse: _vm.buttonReverse } ]},[(_vm.showConfrim)?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(" "+_vm._s(_vm.confirmText)+" ")]):_vm._e(),(_vm.showCancel)?_c('button',{staticClass:"btn btn-gray",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]):_vm._e()]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }